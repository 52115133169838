import React, { Component } from 'react';
// import logo from '../../../../assets/images'

class Connect extends Component {

    render() {
        return (
            <div>
                <div className='connect_div'>
                    <div className='connect_logo'>
                      
                    </div>
                    <div className='connect_text'>
                    <text className='bold'>Start New Project Today...</text>
                    </div>
                    <div className='connect_button m-r-30'>
                    <button className='button'>Contact Us Today</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Connect;