import React from "react";

const Footer = props => {
    return (
    <footer className="footer">
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-6 footer-copyright">
                    {/* z<p className="mb-0"><a href="https://app.mohandess.com/privacy/" rel="noopener noreferrer" target="_blank">Privacy Policy</a> </p> */}
                </div>
                {/* <div className="col-md-6">
                    <p className="pull-right mb-0">Hand crafted & made with
                        <i className="fa fa-heart"></i>
                    </p>
                </div> */}
            </div>
        </div>
</footer>
)};

export default Footer;