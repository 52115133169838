import React ,{ Fragment, useState, useEffect } from 'react';
import data from '../../../jsonData/data.json';
import image from './../images/about/about.jpg';
const Aboutus = ({ history }) => {
    const [landingPageData, setLandingPageData] = useState({...data.About});
    return (
        <>
         <div id="about">
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-md-6">
          {" "}
          <img src={image} className="img-responsive" alt="" />{" "}
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="about-text">
            <h2>About Us</h2>
            <p>{landingPageData ? landingPageData.paragraph : "loading..."}</p>
            <h3>Why Choose Us?</h3>
            <div className="list-style">
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <ul>
                  {landingPageData
                    ? landingPageData.Why.map((d, i) => (
                        <li key={`${d}-${i}`}>{d}</li>
                      ))
                    : "loading"}
                </ul>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <ul>
                  {landingPageData
                    ? landingPageData.Why2.map((d, i) => (
                        <li key={`${d}-${i}`}> {d}</li>
                      ))
                    : "loading"}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Contact/> */}
    </div>
  </div>
  </>
    );
}

export default Aboutus;
