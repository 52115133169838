export const Market_Exp = [
  {
    "name": "Africa",
    "value": "Africa"
  },
  {
    "name": "ANZ",
    "value": "ANZ"
  },
  {
    "name": "Asia Pacific",
    "value": "Asia"
  },
  {
    "name": "Australasia",
    "value": "Australasia"
  },
  {
    "name": "Australia",
    "value": "Aus"
  },

  {
    "name": "EMEA",
    "value": "EMEA"
  },
  {
    "name": "Europe ",
    "value": "Europe"
  },
  {
    "name": "JAPAC",
    "value": "JAPAC"
  },
  {
    "name": "MENA",
    "value": "MENA"
  }, 
  {
    "name": "Middle East",
    "value": "Middle_East"
  },
  {
    "name": "North America",
    "value": "NAmerica"
  },
  {
    "name": "Oceania",
    "value": "Oceania"
  },
  {
    "name": "South America",
    "value": "SAmerica"
  },
  {
    "name": "South Asia",
    "value": "S_Asia"
  },
  {
    "name": "South East Asia",
    "value": "SE_Asia"
  },  
]