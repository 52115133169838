import React from "react";
import ReactDOM from "react-dom";

import CheckoutForm from './CheckoutForm';

const stripepayment = () => {
    return (
       <></>
    );
  };
  ReactDOM.render(<stripepayment />, document.getElementById('root'));
  export default stripepayment;  
