export default [
    { name: 'Design Engineering' },
    { name: 'Civil Engineering' },
    { name: 'Structural Engineering' },
    { name: 'Architectural Engineering' },
    { name: 'Mechanical Engineering' },
    { name: 'HVAC' },
    { name: 'MEP' },


];