export const Sector = [
  {
    "name": "AI, AR, VR, IOT",
    "value": "AI"
  },
  {
    "name": "Automobile",
    "value": "Automobile"
  },
  {
    "name": "Banking & Finance",
    "value": "Banking"
  },
  {
    "name": "B2B Businesses",
    "value": "Businesses"
  },
  {
    "name": "Consumer Appliances",
    "value": "Appliances"
  },
  {
    "name": "eCommerce",
    "value": "EComm"
  },
  {
    "name": "Education",
    "value": "Education"
  },   
  {
    "name": "Fashion & Lifestyle",
    "value": "Fashion"
  }, 
  {
    "name": "FMCG",
    "value": "FMCG"
  },
  {
    "name": "Healthcare",
    "value": "Healthcare"
  },
  {
    "name": "Insurance",
    "value": "Insurance"
  },
  {
    "name": "IT/ITES",
    "value": "ITES"
  },
  {
    "name": "Media & Entertainment",
    "value": "Media"
  },
  {
    "name": "Personal Care & Beauty",
    "value": "Personal"
  },
  {
    "name": "Retail",
    "value": "Retail"
  },
  {
    "name": "StartUps",
    "value": "StartUps"
  },
  {
    "name": "Telecom",
    "value": "Telecom"
  }, 
  {
    "name": "Travel & Hospitality",
    "value": "Travel"
  }
  ]